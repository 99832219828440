import React from 'react';
import {Form} from "react-final-form";
import {Select, Switches, TextField} from "mui-rff";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays';
import Typography from "@material-ui/core/Typography";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from "@material-ui/core/Grid";
import Message from "../Message/Message";
import {isEmpty} from "lodash";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    flexCenter: {
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        flexDirection:'row'
    },
    questionWrapper: {
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        borderStyle: 'dotted',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 3,
        marginBottom: theme.spacing(2)
    },
    subtitle: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}));

validate.validators.array = (arrayItems, itemConstraints) => {

    const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
        const error = validate(item, itemConstraints, {fullMessages: false})
        if (error) errors.push({...error, index})
        return errors
    }, []);

    return isEmpty(arrayItemErrors) ? null : arrayItemErrors
};

const ExamsEditForm = ({ initialValues, modules, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    const defaultQuestion = { title: '', order: 1, options: [{ value: '', is_correct: false }, { value: '', is_correct: false }, { value: '', is_correct: false }] };

    // Reglas de validación de formulario
    const constraints = {
        title: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        module_id: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            inclusion: {
                within: modules.map(module => module.id),
                message: 'Debe de seleccionar un valor del listado'
            }
        },
        questions: {
            array: {
                title: {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    }
                },
                order: {
                    presence: {
                        allowEmpty: false,
                        message: 'Debe de llenar este campo'
                    },
                    numericality: {
                        onlyInteger: true,
                        greaterThanOrEqualTo: 0,
                        message: "El valor debe ser mayor o igual a 0"
                    }
                },
                options: {
                    array: {
                        value: {
                            presence: {
                                allowEmpty: false,
                                message: 'Debe de llenar este campo'
                            }
                        }
                    }
                }
            }
        }
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                title: values.title,
                module_id: values.module_id,
                questions: values.questions,
            };

            // Enviar datos a API
            await axios.put(
                `${API_URL}/exams/${values.id}`,
                data
            );

            // Enviar mensaje de éxito a componente padre
            onSuccess('Examen actualizado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError('Ocurrió un error actualizando.');
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            title: values.title,
            module_id: values.module_id,
            questions: values.questions
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid) {
            return;
        } else {
            return formatValidation(valid);
        }
    }

    const formatValidation = (valid) => {

        if(valid !== 'Valid'){

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                if(key === 'questions' || key === 'options'){
                    let newValues = [];

                    for(let i = 0; i < valid[key].length; i++){
                        newValues[valid[key][i].index] = formatValidation(valid[key][i]);
                    }

                    valid[key] = newValues;
                } else if(key !== 'index'){
                    valid[key] = valid[key][0];
                }
            });

        }

        return valid;

    };

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    // Opciones de select
    const modulesOptions = modules.map(module => (
        <MenuItem key={module.id} value={module.id}>{ module.name }</MenuItem>
    ));

    const renderQuestions = (fields, push, values) => {
        return fields.map((name, index) => (
            <div key={name} className={ classes.questionWrapper } >
                <Grid container spacing={3} justify="space-between" alignItems="flex-end" direction="row">
                    <Grid item sm={8} xs={12}>
                        <TextField label={`Pregunta #${index + 1}`} name={`${name}.title`} />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <TextField label="Orden" type="number" name={`${name}.order`} />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                        <Button
                            type="button"
                            color="secondary"
                            onClick={ () => fields.remove(index) }
                            startIcon={<DeleteIcon />}
                        >
                            Pregunta
                        </Button>
                    </Grid>
                </Grid>
                {
                    values.questions[index].options.length < 2 ?
                        <Message message="Debe de agregar por lo menos 2 opciones." style={{ marginTop: 20 }} /> :
                        null
                }
                <FieldArray name={`${name}.options`}>
                    {({fields}) => renderOptions(fields) }
                </FieldArray>
                <div className={ classes.flexCenter }>
                    <Button
                        color="primary"
                        type="button"
                        style={{ marginTop: 20 }}
                        startIcon={<AddIcon />}
                        onClick={() => push(`${name}.options`, { value: '', is_correct: false })}
                    >
                        Agregar Opción
                    </Button>
                </div>
            </div>
        ))
    };

    const renderOptions = (fields) => {
        return fields.map((name, index) => (
            <Grid key={name} container spacing={3} justify="space-between" alignItems="flex-end" direction="row">
                <Grid item sm={8} xs={12}>
                    <TextField label={`Opción #${index + 1}`} name={`${name}.value`} />
                </Grid>
                <Grid item sm={2} xs={12}>
                    <div style={{ marginBottom: -9 }}>
                        <Switches
                            name={`${name}.is_correct`}
                            data={{label: '¿Es correcta?', value: true}}
                        />
                    </div>
                </Grid>
                <Grid item sm={2} xs={12}>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={ () => fields.remove(index) }
                        startIcon={<DeleteIcon />}
                    >
                        Opción
                    </Button>
                </Grid>
            </Grid>
        ));
    };

    return (
        <Form
            onSubmit={onSubmit}
            mutators={{
                ...arrayMutators
            }}
            initialValues={initialValues}
            validate={validateForm}
            render={({
                handleSubmit,
                values,
                submitError,
                form,
                form: {
                    mutators: { push, pop }
                }
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Select name="module_id" label="Módulo" formControlProps={{ margin: 'normal' }}>
                        { modulesOptions }
                    </Select>
                    <TextField label="Título" name="title" />
                    <Typography variant="subtitle2" className={ classes.subtitle }>Preguntas</Typography>
                    {
                        values.questions.length === 0 ?
                            <Message message="Debe de agregar por lo menos una pregunta." /> :
                            null
                    }
                    <FieldArray name="questions">
                        {({ fields }) => renderQuestions(fields, push, values)}
                    </FieldArray>
                    <div className={ classes.flexCenter }>
                        <Button
                            color="secondary"
                            type="button"
                            startIcon={<AddIcon />}
                            onClick={() => push('questions', {...defaultQuestion, order: values.questions.length + 1})}
                        >
                            Agregar Pregunta
                        </Button>
                    </div>
                    <div className={ classes.buttonWrapper }>
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={submitting}
                            startIcon={ submitting ? <CircularProgress size={18}/> : null }
                        >
                            Actualizar
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default ExamsEditForm;

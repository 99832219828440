import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import {signOut} from "../../store/actions/auth";
import {connect, useSelector} from "react-redux";
import axios from "axios";
import {API_URL} from "../../config";
import Snack from "../Snack/Snack";
import UsersIndex from "../../containers/Admin/Users/UsersIndex/UsersIndex";
import UsersView from "../../containers/Admin/Users/UsersView/UsersView";
import UsersCreate from "../../containers/Admin/Users/UsersCreate/UsersCreate";
import UsersEdit from "../../containers/Admin/Users/UsersEdit/UsersEdit";
import ModulesIndex from "../../containers/Admin/Modules/ModulesIndex/ModulesIndex";
import ModulesCreate from "../../containers/Admin/Modules/ModulesCreate/ModulesCreate";
import ModulesEdit from "../../containers/Admin/Modules/ModulesEdit/ModulesEdit";
import LessonsIndex from "../../containers/Admin/Lessons/LessonsIndex/LessonsIndex";
import LessonsCreate from "../../containers/Admin/Lessons/LessonsCreate/LessonsCreate";
import LessonsEdit from "../../containers/Admin/Lessons/LessonsEdit/LessonsEdit";
import ExamsIndex from "../../containers/Admin/Exams/ExamsIndex/ExamsIndex";
import ExamsCreate from "../../containers/Admin/Exams/ExamsCreate/ExamsCreate";
import ExamsEdit from "../../containers/Admin/Exams/ExamsEdit/ExamsEdit";
import Home from "../../containers/Home/Home";
import ExamsAnswer from "../../containers/Exams/ExamsAnswer/ExamsAnswer";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        textAlign: 'left'
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    },
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    mainContainer: {
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch'
    }
}));

const Dashboard = ({ signOut, readError, authMessage, history }) => {

    const classes = useStyles();

    const authState = useSelector((state) => state.authReducer);

    // State de drawer
    const [open, setOpen] = React.useState(false);

    // State de snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Función que abre el drawer
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    // Función que cierra el drawer
    const handleDrawerClose = () => {
        setOpen(false);
    };

    // Función que cierra la sesión
    const handleLogout = async () => {

        try {

            await axios.post(API_URL + "/logout");
            signOut();

        } catch (error) {
            setSnackbarMessage('Ocurrió un error cerrando la sesión');
            setSnackbarOpen(true);
        }
    };

    return (
        <div style={ { backgroundColor: '#fafafa' } } className={ classes.mainContainer }>
            <AppBar position="sticky">
                <Toolbar className={classes.toolbar}>
                    {
                        authState.roleId === 1 ?
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={ handleDrawerOpen }
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>:
                            null
                    }
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        <NavLink to="/users" style={{ textDecoration: 'none', color: 'black'}} >
                            SDA Courses
                        </NavLink>
                    </Typography>
                    <IconButton color="inherit" onClick={ handleLogout }>
                        <ExitToAppIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {
                authState.roleId === 1 ?
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        onClose={handleDrawerClose}
                        open={open}
                    >
                        <List>
                            <div>
                                <ListItem button component={NavLink} to="/users" activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <PersonIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Usuarios"/>
                                </ListItem>
                                <ListItem button component={NavLink} to="/modules"
                                          activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <FolderIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Módulos"/>
                                </ListItem>
                                <ListItem button component={NavLink} to="/lessons"
                                          activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <FolderOpenIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Lecciones"/>
                                </ListItem>
                                <ListItem button component={NavLink} to="/exams" activeClassName="Mui-selected">
                                    <ListItemIcon>
                                        <AssignmentIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Exámenes"/>
                                </ListItem>
                            </div>
                        </List>
                    </Drawer> :
                    null
            }
            <main className={classes.content}>
                    <Switch>
                        { authState.roleId === 1 && <Route path="/users" exact component={UsersIndex}/> }
                        { authState.roleId === 1 && <Route path="/users/create" exact component={UsersCreate}/>}
                        { authState.roleId === 1 && <Route path="/users/:id" exact component={UsersView}/>}
                        { authState.roleId === 1 && <Route path="/users/:id/edit" exact component={UsersEdit}/> }
                        { authState.roleId === 1 && <Route path="/modules" exact component={ModulesIndex}/> }
                        { authState.roleId === 1 && <Route path="/modules/create" exact component={ModulesCreate}/>}
                        { authState.roleId === 1 && <Route path="/modules/:id/edit" exact component={ModulesEdit}/> }
                        { authState.roleId === 1 && <Route path="/lessons" exact component={LessonsIndex}/> }
                        { authState.roleId === 1 && <Route path="/lessons/create" exact component={LessonsCreate}/>}
                        { authState.roleId === 1 && <Route path="/lessons/:id/edit" exact component={LessonsEdit}/> }
                        { authState.roleId === 1 && <Route path="/exams" exact component={ExamsIndex}/> }
                        { authState.roleId === 1 && <Route path="/exams/create" exact component={ExamsCreate}/>}
                        { authState.roleId === 1 && <Route path="/exams/:id/edit" exact component={ExamsEdit}/> }
                        { authState.roleId === 1 && <Redirect from="/" to="/users" /> }
                        { authState.roleId === 2 && <Route path="/student/home" exact component={Home}/> }
                        { authState.roleId === 2 && <Route path="/student/exams/:id" exact component={ExamsAnswer}/> }
                        { authState.roleId === 2 && <Redirect from="/" to="/student/home" /> }
                    </Switch>
            </main>
            <Snack message={ snackbarMessage } open={ snackbarOpen } handleClose={ () => setSnackbarOpen(false) } />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () =>
            dispatch(signOut())
    };
};

export default connect(null, mapDispatchToProps)(Dashboard);

import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import {API_URL} from "../../../../config";
import ExamsCreateForm from "../../../../components/Forms/ExamsCreateForm";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ExamsCreate = ({ enqueueSnackbar }) => {

    const classes = useStyles();

    // State de módulos
    const [modules, setModules] = useState([]);

    /* *** State de formulario *** */

    const formFields = {
        title: '',
        module_id: '',
        questions: []
    };

    /* *** State de formulario *** */

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {

        // Función que carga los módulos
        const loadModules = async () => {

            // Mostrar indicador de carga
            setIsLoading(true);

            try {

                // Hacer request
                const response = await axios.get(
                    `${API_URL}/modules`,
                    {
                        params: {
                            sort: 'name',
                            direction: 'asc'
                        }
                    }
                );

                // Actualizar state
                setModules(response.data);

            } catch(error) {

                enqueueSnackbar('Ocurrió un error cargando los módulos.');

            }

            // Esconder indicador de carga
            setIsLoading(false);
        };

        // Cargar módulos
        loadModules();

    }, []);

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={ classes.progress }>
                                <CircularProgress />
                            </div> :
                            <Paper className={classes.paper}>
                                <LinearProgress style={ { visibility: isSubmitting ? 'visible' : 'hidden' } } />
                                <Toolbar className={classes.padding}>
                                    <IconButton className={ classes.backButton } component={ NavLink } to="/exams">
                                        <ArrowBackIcon color="secondary" />
                                    </IconButton>
                                    <Typography className={classes.title} variant="h6" id="title">Nuevo Examen</Typography>
                                </Toolbar>
                                <div>
                                    <div className={classes.padding}>
                                        <ExamsCreateForm
                                            initialValues={ formFields }
                                            modules={ modules }
                                            submitting={isSubmitting}
                                            onSuccess={ (message) => enqueueSnackbar(message) }
                                            onError={ (message) => enqueueSnackbar(message) }
                                            onLoadingChange={ (loading) => setIsSubmitting(loading)}
                                        />
                                    </div>
                                </div>
                            </Paper>
                    }
                </div>
            </Container>
        </>
    )
};

export default withSnackbar(ExamsCreate);

import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: { paddingVertical: 30, paddingHorizontal: 40 },
    titleWrapper: {
        textAlign: 'center'
    },
    title: {
        fontFamily: 'Times-Bold',
        fontSize: 24
    },
    header: {
        fontSize: 12,
        marginTop: 30
    },
    headerLabel: {
        fontFamily: 'Courier',
    },
    headerValue: {
        fontFamily: 'Courier-Bold'
    },
    optionsWrapper: {
        marginTop: 8,
    },
    questionWrapper: {
        marginTop: 16,
        fontFamily: 'Courier',
        fontSize: 12,
        backgroundColor: '#fafafa',
        padding: 20,
        borderRadius: 4
    },
    questionLabel: {
        fontFamily: 'Courier-Bold'
    },
    correctAnswer: {
        color: 'green',
        fontFamily: 'Courier-Bold'
    },
    incorrectAnswer: {
        color: 'red',
        fontFamily: 'Courier-Bold'
    },
    selectedAnswer: {
        fontFamily: 'Courier-Bold'
    }
});

// Create Document Component
const ExamResults = ({exam, user}) => {

    const renderQuestions = () => {
        return JSON.parse(exam.pivot.answers).map((question, index) => (
            <View style={styles.questionWrapper} key={index}>
                <Text style={styles.questionLabel}>{`${index + 1}. ${question.title}`}</Text>
                <View style={styles.optionsWrapper}>
                    { renderOptions(index) }
                </View>
            </View>
        ));
    };

    const renderOptions = (index) => {
        return JSON.parse(exam.pivot.answers)[index].options.map(option => (
                option.selected ?
                    <Text style={option.correct ? styles.correctAnswer : styles.incorrectAnswer}>{option.value}</Text> :
                    <Text>{option.value}</Text>
        ));
    };

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>SDA Courses</Text>
            </View>
            <View style={styles.header}>
                <Text style={styles.headerLabel}>Examen: <Text style={styles.headerValue}> {exam.title}</Text></Text>
                <Text style={styles.headerLabel}>Estudiante:<Text style={styles.headerValue}> {user.name}</Text></Text>
                <Text style={styles.headerLabel}>Calificación:<Text
                    style={styles.headerValue}> {exam.pivot.grade}</Text></Text>
            </View>
            {
                exam.pivot.answers !== null ?
                    renderQuestions() :
                    null
            }
        </Page>
    </Document>
};

export default ExamResults;

import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import WarningIcon from "@material-ui/icons/Warning";
import Container from "@material-ui/core/Container";
import ExamsAnswerForm from "../../../components/Forms/ExamsAnswerForm";
import {API_URL} from "../../../config";
import Message from "../../../components/Message/Message";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "../../../components/Dialog/Dialog";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    backButton: {
        marginRight: 8
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        display: 'flex',
        flex: 1,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

const ExamsAnswer = ({ enqueueSnackbar, match, history }) => {

    const classes = useStyles();

    // ID del registro
    const id = match.params.id;

    // State de módulos
    const [exam, setExam] = useState([]);

    /* *** State de formulario *** */

    const [result, setResult] = useState(null);

    const [error, setError] = useState('');

    const [formFields, setFormFields] = useState({exam: null, questions: []});

    /* *** State de formulario *** */

    // State de diálogo
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {

        const init = async () => {
            await loadExam();
        };

        init();

    }, []);

    // Función que carga el registro
    const loadExam = async () => {

        setIsLoading(true);

        try {

            const userExamsResponse = await axios.get(`${API_URL}/course/exam/${id}`);
            const userExamsData = userExamsResponse.data;

            if(userExamsData.filter(exam => exam.grade >= 70).length === 0){
                const examResponse = await axios.get(`${API_URL}/exams/${id}`);
                const examData = examResponse.data.exam;

                setExam(examData);

                const questions = examData.questions.map(question => ({ id: question.id, answer: null }));

                setFormFields({exam: examData.id, questions});
            } else {
                setError("Ya has aprobado este examen.");
            }

        } catch(error){

            setError('Ocurrió un error cargando el registro.');

            enqueueSnackbar('Ocurrió un error cargando el registro.');

        }

        // Esconder indicador de carga
        setIsLoading(false);

    };

    const handleSuccess = (grade) => {
        setResult(grade);
    };

    const handleBack = () => {
        if(result || result === 0){
            history.push('/home');
        } else {
            setIsDialogOpen(true);
        }
    };

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={ classes.progress }>
                                <CircularProgress />
                            </div> :
                            error !== '' ?
                                <div className={ classes.errorWrapper }>
                                    <WarningIcon color="primary" style={ { marginRight: 8 } }/>
                                    <Typography>{ error }</Typography>
                                </div> :
                                <Paper className={classes.paper}>
                                    <LinearProgress style={ { visibility: isSubmitting ? 'visible' : 'hidden' } } />
                                    <Toolbar className={classes.padding}>
                                        <IconButton className={ classes.backButton } onClick={handleBack}>
                                            <ArrowBackIcon color="secondary" />
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6" id="title">{exam.title}</Typography>
                                    </Toolbar>
                                    <div>
                                        <div className={classes.padding}>
                                            {
                                                result || result === 0 ?
                                                    <div>
                                                        <Message message={
                                                            result >= 70 ?
                                                                `¡Felicidades! has aprobado este examen con una calificación de ${result}.` :
                                                                `Lo sentimos, la calificación mínima para aprobar es 70. Tu calificación fue ${result}. Estudia más y vuelve a intentarlo.`
                                                        }/>
                                                        <div className={ classes.buttonWrapper }>
                                                            <Button color="secondary" onClick={() => history.push(`/home`)}>Regresar al curso</Button>
                                                        </div>
                                                    </div> :
                                                    <ExamsAnswerForm
                                                        initialValues={ formFields }
                                                        examData={exam}
                                                        submitting={isSubmitting}
                                                        onSuccess={ handleSuccess }
                                                        onError={ (message) => enqueueSnackbar(message) }
                                                        onLoadingChange={ (loading) => setIsSubmitting(loading)}
                                                    />
                                            }
                                        </div>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                onAccept={ () => history.push('/home') }
                title="Abandonar examen"
            >
                <DialogContentText id="alert-dialog-description">
                    ¿Estás seguro que deseas salir? Tu progreso se perderá.
                </DialogContentText>
            </Dialog>
        </>
    )
};

export default withSnackbar(ExamsAnswer);

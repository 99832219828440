import React from 'react';
import {Form} from "react-final-form";
import {Select, TextField} from "mui-rff";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import validate from "validate.js";
import axios from "axios";
import {API_URL} from "../../config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginTop: 16
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
    formControl: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: '100%',
    },
}));

const UsersEditForm = ({ initialValues, roles, onSuccess, onError, onLoadingChange, submitting }) => {

    const classes = useStyles();

    // Reglas de validación de formulario
    const constraints = {
        name: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        username: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            }
        },
        email: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    email: {
                        message: 'Debe de ser un correo electrónico válido'
                    }
                }
            } else {
                return null
            }
        },
        password: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return {
                    length: {
                        minimum: 4,
                        message: 'Debe de contener por lo menos 4 caracteres'
                    }
                }
            } else {
                return null;
            }
        },
        password_confirmation: (value, attributes, attributeName, options, constraints) => {
            if(value) {
                return  {
                    equality: {
                        attribute: "password",
                        message: 'Las contraseñas no coinciden'
                    }
                }
            } else {
                return null;
            }
        },
        role_id: {
            presence: {
                allowEmpty: false,
                message: 'Debe de llenar este campo'
            },
            inclusion: {
                within: roles.map(role => role.id),
                message: 'Debe de seleccionar un valor del listado'
            }
        },
    };

    // Función que se ejecuta al enviar formulario
    async function onSubmit(values, form) {

        // Mostrar indicador de carga
        onLoadingChange(true);

        try {

            // Datos a enviar
            const data = {
                name: values.name,
                email: values.email ? values.email : null,
                username: values.username,
                password: values.password ? values.password: null,
                password_confirmation: values.password_confirmation ? values.password_confirmation : null,
                role_id: values.role_id
            };

            // Enviar datos a API
            await axios.put(
                `${API_URL}/users/${values.id}`,
                data
            );

            // Enviar mensaje de éxito a componente padre
            onSuccess('Usuario actualizado.');

            // Esconder indicador de carga
            onLoadingChange(false);

        } catch (error) {

            // Esconder indicador de carga
            onLoadingChange(false);

            // Dependiendo del error, mostrar mensajes
            switch(error.response.status){
                case 400:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                case 422:
                    // Mostrar mensaje
                    onError("Se encontraron uno o más errores de validación.");
                    return handleServerError(error.response.data.errors);
                default:
                    // Mostrar mensaje
                    onError('Ocurrió un error actualizando.');
                    break;
            }

        }

    }

    // Función que valida el formulario
    async function validateForm(values) {

        // Validar campos
        let valid = validate({
            name: values.name,
            username: values.username,
            email: values.email,
            password: values.password,
            password_confirmation: values.password_confirmation,
            role_id: values.role_id
        }, constraints, {fullMessages: false});

        // Si no hay error
        if(!valid)
            return;
        else {

            // Modificar objeto para mostrar errores en formulario
            Object.keys(valid).forEach((key, index) => {
                valid[key] = valid[key][0];
            });

            return valid;
        }
    }

    // Función que manipula objeto para mostrar errores
    const handleServerError = (error) => {

        Object.keys(error).forEach((key, index) => {
            error[key] = error[key][0];
        });

        return error;
    };

    // Opciones de select
    const rolesOptions = roles.map(role => (
        <MenuItem key={role.id} value={role.id}>{ role.name }</MenuItem>
    ));

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validateForm}
            render={({ handleSubmit, values, submitError, form }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Nombre" name="name" />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Usuario" name="username" />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <TextField label="Correo Electrónico" name="email" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Contraseña" name="password" type="password"/>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <TextField label="Confirmar Contraseña" name="password_confirmation" type="password"/>
                        </Grid>
                    </Grid>
                    <Select name="role_id" label="Rol" formControlProps={{ margin: 'normal' }}>
                        { rolesOptions }
                    </Select>
                    <div className={ classes.buttonWrapper }>
                        <Button
                            color="secondary"
                            type="submit"
                            disabled={submitting}
                            startIcon={ submitting ? <CircularProgress size={18}/> : null }
                        >
                            Actualizar
                        </Button>
                    </div>
                </form>
            )}
        />
    );
};

export default UsersEditForm;

import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import WarningIcon from '@material-ui/icons/Warning';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {NavLink} from "react-router-dom";
import {withSnackbar} from "notistack";
import axios from 'axios';
import {API_URL} from "../../../../config";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import * as moment from 'moment';
import {PDFDownloadLink} from "@react-pdf/renderer";
import ExamResults from "../../../../components/Documents/ExamResults";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    progress: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inlineProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    padding: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    title: {
        flex: '1 1 100%',
    },
    backButton: {
        marginRight: 8
    },
    gallery: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    image: {
        height: 200,
        margin: 2
    },
    errorWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: '100%'
    },
    appBarSpacer: theme.mixins.toolbar,
    iconError: {
        color: theme.palette.error.main
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(8),
        height: theme.spacing(8),
    },
    stat: {
        padding: 20,
        marginBottom: 20,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.primary.main,
        borderTopWidth: 2,
        backgroundColor: '#FAFAFA',
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        marginRight: 4,
        marginLeft: 4,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    percent: {
        color: theme.palette.primary.main,
        marginRight: 8
    },
    statsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    }
}));

const UsersView = ({ history, match, enqueueSnackbar }) => {

    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [course, setCourse] = useState([]);
    const [userLessons, setUserLessons] = useState([]);
    const [userExams, setUserExams] = useState([]);

    // State de indicador de carga
    const [isLoading, setIsLoading] = useState(true);

    const [error, setError] = useState('');

    // ID del documento
    const id = match.params.id;

    useEffect(() => {

        const init = async () => {
            await loadUser();
        };

        init();
    }, []);

    const loadUser = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/users/${id}/progress`);
            setUser(response.data.user);
            setCourse(response.data.modules);
            setUserExams(response.data.user_exams);
            setUserLessons(response.data.user_lessons);

            // console.log(JSON.parse(response.data.user_exams[0].pivot.answers));
        } catch(error){

            setError('Ocurrió un error cargando el usuario.');

            // Mostrar mensaje de error
            enqueueSnackbar('Ocurrió un error cargando el usuario.');

        }

        setIsLoading(false);
    };

    const getCount = (data, type) => {

        let count = 0;

        for(let i = 0; i < data.length; i++){
            if(type === 'exam') {
                count += data[i].exams.length;
            } else if(type === 'lesson'){
                count += data[i].lessons.length;
            }
        }

        return count;
    };

    const renderExams = (passed) => {
        return userExams.filter(exam => passed ? parseFloat(exam.pivot.grade) >= 70 : parseFloat(exam.pivot.grade) < 70).map(exam => (
            <TableRow key={exam.pivot.id}>
                <TableCell component="th" scope="row">
                    {exam.pivot.id}
                </TableCell>
                <TableCell>{exam.title}</TableCell>
                <TableCell>{exam.pivot.grade}</TableCell>
                <TableCell>{moment(exam.created_at).format('D MMM YYYY')}</TableCell>
                <TableCell>
                    <PDFDownloadLink style={ { textDecoration: 'none' } } document={ <ExamResults exam={exam} user={user} />} fileName={`exam_${exam.pivot.id}.pdf`}>
                        {({ blob, url, loading, error }) => {
                                return <IconButton color="primary" disabled={ loading } aria-label="delete">
                                    <PrintIcon />
                                </IconButton>
                            }}
                    </PDFDownloadLink>
                </TableCell>
            </TableRow>
        ));
    }

    return (
        <>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <div className={classes.root}>
                    {
                        isLoading ?
                            <div className={classes.progress}>
                                <CircularProgress/>
                            </div> :
                            error !== '' ?
                                <div className={classes.errorWrapper}>
                                    <WarningIcon color="primary" style={{marginRight: 8}}/>
                                    <Typography>{error}</Typography>
                                </div> :
                                <Paper className={[classes.paper]}>
                                    <Toolbar>
                                        <IconButton className={classes.backButton} component={NavLink} to="/users">
                                            <ArrowBackIcon color="secondary"/>
                                        </IconButton>
                                        <Typography className={classes.title} variant="h6"
                                                    id="name">Usuario</Typography>
                                    </Toolbar>
                                    <div>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <Avatar className={classes.avatar}>{user.name[0]}</Avatar>
                                            <Typography variant="h6"
                                                        style={{marginTop: 10}}>{user.name}</Typography>
                                            <Typography variant="subtitle2"
                                                        style={{marginBottom: 20}}>#{user.id}</Typography>
                                        </div>
                                    </div>
                                    <div className={classes.padding}>
                                        <div className={ classes.statsWrapper }>
                                            <div className={classes.stat}>
                                                <Typography variant="h4" className={classes.percent}>{userLessons.length}/{getCount(course, 'lesson')}</Typography>
                                                <div>
                                                    <Typography variant="h6">Lecciones</Typography>
                                                    <Typography variant="subtitle2" style={{ color: '#bcbcbc'}}>{(userLessons.length / getCount(course, 'lesson') * 100).toFixed(0)}% completado</Typography>
                                                </div>
                                            </div>
                                            <div className={classes.stat}>
                                                <Typography variant="h4" className={classes.percent}>{userExams.filter(exam => parseFloat(exam.pivot.grade) >= 70).length}/{getCount(course, 'exam')}</Typography>
                                                <div>
                                                    <Typography variant="h6">Exámenes</Typography>
                                                    <Typography variant="subtitle2" style={{ color: '#bcbcbc'}}>{ (userExams.filter(exam => parseFloat(exam.pivot.grade) >= 70).length / getCount(course, 'exam') * 100).toFixed(0) }% completado</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <Typography variant="subtitle2" style={ { marginBottom: 10 } }>Exámenes Aprobados</Typography>
                                        <TableContainer>
                                            <Table className={classes.table} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Examen</TableCell>
                                                        <TableCell>Calificación</TableCell>
                                                        <TableCell>Fecha</TableCell>
                                                        <TableCell>Acciones</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { renderExams(true) }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography variant="subtitle2" style={ { marginBottom: 10, marginTop: 20 } }>Exámenes Reprobados</Typography>
                                        <TableContainer>
                                            <Table className={classes.table} size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Examen</TableCell>
                                                        <TableCell>Calificación</TableCell>
                                                        <TableCell>Fecha</TableCell>
                                                        <TableCell>Acciones</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    { renderExams(false) }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </Paper>
                    }
                </div>
            </Container>
        </>
    );
};

export default withSnackbar(UsersView);
